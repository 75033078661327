<template>
  <CountryFlag :country="country" />
</template>

<script>
import CountryFlag from '@hypefactors/shared/js/components/core/CountryFlag'

import { LANGUAGE_TO_COUNTRY } from '../../constants/trans'

export default {
  name: 'LanguageFlag',

  components: {
    CountryFlag
  },

  props: {
    language: {
      type: String,
      required: true
    }
  },

  computed: {
    country () {
      return LANGUAGE_TO_COUNTRY[this.language]
    }
  }
}
</script>
