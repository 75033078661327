<template>
  <div
    v-click-away="closeMenu"
    class="NavDropdown"
  >
    <div class="NavDropdown__Trigger">
      <div
        :class="{ hasArrow }"
        class="NavDropdown__TriggerWrap"
      >
        <!-- Places the trigger and passes the toggle as a prop -->
        <slot
          :toggle="toggle"
          name="trigger"
        />
      </div>
      <!-- Places the Arrow -->
      <div
        v-if="hasArrow"
        class="NavDropdown__Arrow"
        @click="toggle"
      >
        <i
          :class="isActive ? 'hf-angle-up': 'hf-angle-down'"
          class="hf"
        />
      </div>
    </div>
    <div class="NavDropdown__MenuContainer">
      <div
        :class="{ isActive, ['NavDropdown__Menu--' + attachToSide]: true }"
        :style="{ minWidth: minWidth }"
        class="NavDropdown__Menu"
      >
        <template v-if="items.length || $slots.extraItems">
          <slot name="extraItems" />
          <div
            v-for="(item, i) in items"
            :key="i"
            class="NavDropdown__Item"
          >
            <slot :item="item">
              <a
                :href="item.href || null"
              >
                {{ item.body }}
              </a>
            </slot>
          </div>
        </template>
        <div
          v-else
          class="NavDropdown__Item"
        >
          {{ noItemsPlaceholderText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Generates a Dropdown menu
 * Used on {@see module:TheNavigation} for the dropdowns and {@see module:NewsroomAdminBar}
 * @module NavDropdown
 */
export default {
  name: 'NavDropdown',

  props: {
    // A generic array of items
    items: {
      type: [Array, Object],
      default: () => []
    },

    // Whether an arrow is shown or not
    hasArrow: {
      type: Boolean,
      default: false
    },

    // To which side should the dropdown be sticked
    attachToSide: {
      type: String,
      default: 'left',
      validator: v => ['left', 'right'].includes(v)
    },

    // Text to display if no items are in the dropdown
    noItemsPlaceholderText: {
      type: String,
      default () {
        return this.$t('components.nav_dropdown.no_items_placeholder')
      }
    },

    // Set a minimum width of the dropdown
    minWidth: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    // Toggles the dropdown on/off. Passed down to the scoped slot
    toggle (e) {
      e.preventDefault()
      this.isActive = !this.isActive
    },

    closeMenu () {
      if (!this.isActive) return
      this.isActive = false
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

$NavDropdown__MenuPadding: .8rem;

.NavDropdown {

}

.NavDropdown__MenuContainer {
  position: relative;
}

.NavDropdown__Menu {
  top: 100%;
  width: 100%;
  background: $hf__color-gray-darker;
  display: none;
  padding: $NavDropdown__MenuPadding;
  text-align: left;
  border-top: 2px solid $grey-lighter;
  position: absolute;
  z-index: 10;
  left: 0;
  right: auto;
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 150px;

  &.isActive {
    display: block;
  }

  @include tablet {
    right: auto;
    width: auto;
    padding: 0 $NavDropdown__MenuPadding;

    &--right {
      left: auto;
      right: 0;
    }

    @include rtl {
      text-align: right;

      &--right {
        right: auto;
        left: 0;
      }

      &--left {
        right: 0;
        left: auto;
      }
    }
  }
}

.NavDropdown__Item {
  padding: .4rem;

  a {
    color: $hf__color-white;

    &:hover {
      color: $hf__color-white-ter
    }
  }
}

.NavDropdown__Trigger {
  position: relative;

  .NavDropdown__Arrow {
    padding: 0 1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include rtl {
      left: 0;
      right: auto;
    }
  }

  @include tablet {
    .NavDropdown__TriggerWrap {
      &.hasArrow {
        padding-right: 1rem;
        @include rtl {
          padding-left: 1.5rem;
          padding-right: unset;
        }
      }
    }

    .NavDropdown__Arrow {
      padding: 0 .5rem;
    }
  }
}
</style>
