<template>
  <BackgroundImage
    :src="$asset('/images/landing/video_screenshot.jpg')"
    class="AuthLayout is-flex is-column"
    shadow
  >
    <youtube
      v-if="!isMobile && !noVideoBackground"
      ref="player"
      :video-id="video.id"
      :player-vars="video.settings"
      mute
      class="AuthLayout__Background"
    />
    <div class="p-h-l p-v-m is-relative">
      <div class="columns is-mobile is-aligned-justify is-aligned-middle">
        <div class="column is-narrow">
          <div class="AuthLayout__LangSwitcher">
            <TheLanguageSwitcher attach-to-side="left" min-width="200px" />
          </div>
        </div>
        <div class="column is-narrow">
          <img
            :src="$asset('/images/branding/hf_logo_full_white.svg')"
            :alt="$t('general.hypefactors')"
            width="200"
            class="is-block m-h-a"
          >
        </div>
      </div>
    </div>
    <div class="is-flex is-column is-aligned-middle is-aligned-center is-expanded">
      <div class="columns is-width-full is-aligned-center is-position-relative is-marginless">
        <div class="column is-12-mobile is-3-widescreen is-6-tablet">
          <router-view />
        </div>
      </div>
    </div>
  </BackgroundImage>
</template>

<script>
import BackgroundImage from '@hypefactors/shared/js/components/core/BackgroundImage'
import TheLanguageSwitcher from '@hypefactors/shared/js/components/core/TheLanguageSwitcher'

export default {
  components: {
    BackgroundImage,
    TheLanguageSwitcher
  },

  props: {
    noVideoBackground: {
      type: Boolean,
      default: false
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.reportReason(from.query)

    return next()
  },

  data () {
    const youtubeVideoId = 'eQnfUAE-VFM'

    return {
      video: {
        id: youtubeVideoId,
        settings: {
          autoplay: 1,
          loop: 1,
          controls: 0,
          rel: 0,
          showinfo: 0,
          disablekb: 1,
          playlist: youtubeVideoId
        }
      },
      isMobile: window.innerWidth < 768
    }
  },
  methods: {
    /**
     * Report to bugsnag the potential cause of the logout
     * Tell the user why they are here.
     */
    reportReason ({ reason } = {}) {
      if (!reason) return
      switch (reason) {
        case 'no_brands':
          this.handleNoBrands()
          return
        case 'errored_on_app_bootstrap':
          this.handleAppBootstrapError()
          return
        case '401':
          this.handle401()
      }
    },
    /**
     * Error is displayed when the user has no brands attached to their profile
     */
    handleNoBrands () {
      this.$msgbox.alert(this.$t('errors.logged_out_no_brands')).catch(() => {})
    },
    /**
     * Error is displayed when there was an error upon initial app bootstrap
     */
    handleAppBootstrapError () {
      this.$msgbox.alert(this.$t('errors.errored_on_app_bootstrap')).catch(() => {})
    },
    handle401 () {
      this.$msgbox.alert(this.$t('errors.logged_out_401')).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.AuthLayout {
  background-position: center center;
  min-height: 100vh;

  &__FloatingText {
    position: absolute;
    top: 0;
    left: 50px;
    transform: translateY(5%);
    color: $hf__color-white;
  }

  &__Background {
    background: #000;
    position: fixed;
    top: -70px;
    bottom: -70px;
    right: 0;
    left: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &:after {
      content: '';
      @include overlay;
      background: transparentize($hf__color-black, 0.4);
    }
  }

  &__LangSwitcher {
    width: 4rem;
    height: 4rem;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 100%;

    .NavDropdown__Arrow {
      padding-right: 0;
      padding-left: 2px;
    }

    .NavDropdown__TriggerWrap {
      padding-right: 7px !important;
    }
  }

  .links {
    display: flex;
    text-align: right;
    flex-direction: column;
  }

  footer {
    margin-top: $margin-small;
    text-align: center;
    font-size: $hf__font-label-small;
  }
}
</style>
