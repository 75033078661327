<template>
  <nav-dropdown
    :items="unusedLanguages"
    :attach-to-side="attachToSide"
    data-testid="languageSwitcher"
    has-arrow
    class="has-text-transform-none"
    v-bind="$attrs"
  >
    <a
      slot="trigger"
      slot-scope="{ toggle }"
      class="LanguageToggle NavLink NavLink--large"
      @click="toggle"
    >
      <language-flag
        :language="currentLanguage"
        class="m-r-s"
      />
    </a>
    <the-language-switcher-item
      slot-scope="{ item }"
      :language="item"
      :current="currentLanguage"
      @click.native.prevent="changeLanguage(item)"
    />
  </nav-dropdown>
</template>
<script>
import NavDropdown from './NavDropdown'
import TheLanguageSwitcherItem from './TheLanguageSwitcherItem'
import LanguageFlag from './LanguageFlag'

export default {
  name: 'TheLanguageSwitcher',
  components: { LanguageFlag, TheLanguageSwitcherItem, NavDropdown },
  props: {
    attachToSide: {
      type: String,
      default: 'right',
      validator: v => ['left', 'right'].includes(v)
    }
  },
  computed: {
    currentLanguage () {
      return this.$TranslationService.currentLanguage
    },
    unusedLanguages () {
      return this.$TranslationService.supportedLanguages
    }
  },
  methods: {
    async changeLanguage (lang) {
      if (lang === this.currentLanguage) return
      await this.$TranslationService.changeLanguage(lang)
      // if the router does not have a lang parameter, just skip
      if (!this.$route.params.lang) return
      const to = { params: { lang } }
      this.$router.push(to)
    }
  }
}
</script>
