<template>
  <a
    :class="{ 'has-text-primary': language === current }"
    class="NavLanguageSwitcherItem has-nowrap flex is-aligned-middle"
  >
    <language-flag
      :language="language"
      class="m-r-s"
    />
    {{ $t('components.language_switcher.languages.' + language) }}
  </a>
</template>

<script>

import LanguageFlag from './LanguageFlag'

/**
 * @module NavLanguageSwitcherItem
 */
export default {
  name: 'NavLanguageSwitcherItem',
  components: { LanguageFlag },
  props: {
    current: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.NavLanguageSwitcherItem {
  display: flex;

  span {
    flex: 1 1 50%;
  }
}
</style>
